import { Checkbox, CheckboxProps } from 'antd';
import styled, { css } from 'styled-components';
import { getShouldForwardProp } from 'utils/getShouldForwardProp';
import { getMarginsCss, marginPropsToFilter } from 'utils/getMarginsCss';
import { MarginsProps } from 'types';
import { inputAndPickerBorderStyle } from 'consts/styles/inputAndPickerBorderStyle';
import { getTextStyle } from 'utils/getTextStyle';

export type AppCheckboxProps = CheckboxProps & MarginsProps & { bordered?: boolean };

const filteredProps = marginPropsToFilter.concat(['bordered']);

const borderedCss = css`
    ${({ theme }) => css`
        &.ant-checkbox-wrapper {
            ${inputAndPickerBorderStyle};
            padding: ${theme.sizes.marginSmall}px;
            border-radius: ${theme.borders.borderRadiusSmall}px;

            &:has(.ant-checkbox-checked) {
                background-color: ${theme.colors.primary.primary3};
                border: 1px solid ${theme.colors.primary.primary9};
            }

            &:has(.ant-checkbox-disabled) {
                background-color: ${theme.colors.grayscale.gray2};
                border: 1px solid ${theme.colors.grayscale.gray5};
                color: ${theme.colors.grayscale.gray7};

                & .ant-checkbox-inner {
                    background-color: ${theme.colors.grayscale.gray2};
                    border: 1px solid ${theme.colors.grayscale.gray4};
                }
            }
        }
    `}
`;

const StyledCheckbox = styled(Checkbox).withConfig({
    shouldForwardProp: getShouldForwardProp(filteredProps),
})<AppCheckboxProps>`
    ${({ bordered, ...props }) => css`
        ${bordered && borderedCss}

        &.ant-checkbox-wrapper {
            ${getMarginsCss({
                ...props,
            })}
        }

        & .ant-checkbox-inner {
            width: 20px;
            height: 20px;
        }

        & .ant-checkbox + span {
            padding-inline-start: 14px;
            ${getTextStyle('BodyMedium')}
        }

        &.ant-checkbox-wrapper-disabled span {
            color: ${props.theme.colors.grayscale.gray7};
        }

        & .ant-checkbox-disabled .ant-checkbox-inner {
            border-color: ${props.theme.colors.grayscale.gray7};
        }
    `}
`;

export const AppCheckbox = ({ ...props }: AppCheckboxProps) => <StyledCheckbox {...props} />;
