import omit from 'lodash/omit';
import type {
    StaffAppModules,
    Permission,
    PermissionType,
    EditablePermissionsOptionDetails,
    ActionForOtherPermission,
    UserType,
    RoleBasedPermissionName,
} from 'types';
import { flattenObject } from 'utils/flattenObject';

// global permissions from backend
export const PERMISSIONS_NAME = {
    INSTITUTIONS_CREATE: 'INSTITUTIONS_CREATE',
    LAST_MONTH_JOURNALS_ATTENDANCE_EDIT: 'LAST_MONTH_JOURNALS_ATTENDANCE_EDIT',
    JOURNALS_ARCHIVING: 'JOURNALS_ARCHIVING',
    JOURNALS_CHILDREN_DATES_EDIT: 'JOURNALS_CHILDREN_DATES_EDIT',
    ALL_JOURNALS_ACCESS: 'ALL_JOURNALS_ACCESS',
    GROUP_JOURNALS_PRINT: 'GROUP_JOURNALS_PRINT',
    GROUP_JOURNALS_TOPICS: 'GROUP_JOURNALS_TOPICS',
    GROUP_JOURNALS_SCHEDULE: 'GROUP_JOURNALS_SCHEDULE',
    GROUP_JOURNALS_ATTENDANCE: 'GROUP_JOURNALS_ATTENDANCE',
    GROUP_JOURNALS_EVENTS: 'GROUP_JOURNALS_EVENTS',
    GROUP_JOURNALS_CONSULTATIONS: 'GROUP_JOURNALS_CONSULTATIONS',
    GROUP_JOURNALS_PSYCHOLOGICAL_HELP: 'GROUP_JOURNALS_PSYCHOLOGICAL_HELP',
    GROUP_JOURNALS_CHILDREN_LIST_VIEW: 'GROUP_JOURNALS_CHILDREN_LIST_VIEW',
    GROUP_JOURNALS_INSPECTIONS: 'GROUP_JOURNALS_INSPECTIONS',
    SPECIAL_JOURNALS_PRINT: 'SPECIAL_JOURNALS_PRINT',
    SPECIAL_JOURNALS_MANAGE: 'SPECIAL_JOURNALS_MANAGE',
    SPECIAL_JOURNALS_ATTENDANCE: 'SPECIAL_JOURNALS_ATTENDANCE',
    SPECIAL_JOURNALS_CONSULTATIONS: 'SPECIAL_JOURNALS_CONSULTATIONS',
    SPECIAL_JOURNALS_INSPECTIONS: 'SPECIAL_JOURNALS_INSPECTIONS',
    SPECIAL_JOURNALS_QUALIFICATION_BASIS: 'SPECIAL_JOURNALS_QUALIFICATION_BASIS',
    SPECIAL_JOURNALS_CHILDREN_LIST_VIEW: 'SPECIAL_JOURNALS_CHILDREN_LIST_VIEW',
    SPECIAL_JOURNALS_TOPICS: 'SPECIAL_JOURNALS_TOPICS',
    SPECIAL_JOURNALS_ADD_OUT_OF_TIMETABLE: 'SPECIAL_JOURNALS_ADD_OUT_OF_TIMETABLE',
    SPECIAL_JOURNALS_TIMETABLE_VIEW: 'SPECIAL_JOURNALS_TIMETABLE_VIEW',
    DIET_CONFIG: 'DIET_CONFIG',
    TIMETABLE_ALL_EMPLOYEES: 'TIMETABLE_ALL_EMPLOYEES',
    TIMETABLE_OWN: 'TIMETABLE_OWN',
    REMOTE_LESSONS_OWN: 'REMOTE_LESSONS_OWN',
    REMOTE_LESSONS_ALL: 'REMOTE_LESSONS_ALL',
    EVENTS: 'EVENTS',
    ALL_EVENTS_ACCESS: 'ALL_EVENTS_ACCESS',
    EMPLOYEES_LIST: 'EMPLOYEES_LIST',
    CHILDREN_LIST: 'CHILDREN_LIST',
    GROUPS_LIST: 'GROUPS_LIST',
    INSTITUTION_DATA: 'INSTITUTION_DATA',
    ROLES_AND_PERMISSIONS: 'ROLES_AND_PERMISSIONS',
    CHANGES_HISTORY: 'CHANGES_HISTORY',
    PAYMENTS_SETTINGS: 'PAYMENTS_SETTINGS',
    SETTLEMENT_SETTINGS: 'SETTLEMENT_SETTINGS',
    DIET_SETTINGS: 'DIET_SETTINGS',
    CHILDREN_ARCHIVING: 'CHILDREN_ARCHIVING',
    PRINCIPAL_SETTINGS: 'PRINCIPAL_SETTINGS',
    ALL_MAIL_RECEIVERS_ACCESS: 'ALL_MAIL_RECEIVERS_ACCESS',
} as const;

// local permissions for frontend
export const ROLE_BASED_PERMISSIONS_NAME = {
    MESSAGE_READ_STATUS: 'MESSAGE_READ_STATUS',
} as const;

export const PERMISSIONS_ACTION = {
    VIEW: 'VIEW',
    EDIT_AND_ADD: 'EDIT_AND_ADD',
    EDIT_ALL_THE_TIME: 'EDIT_ALL_THE_TIME',
    EDIT_TO_10TH_DAY_OF_MONTH: 'EDIT_TO_10TH_DAY_OF_MONTH',
};

export const EDITABLE_PERMISSIONS_BY_MODULE: Partial<
    Record<StaffAppModules, EditablePermissionsOptionDetails>
> = {
    JOURNALS: {
        LAST_MONTH_JOURNALS_ATTENDANCE_EDIT: 'EDIT_TO_10TH_DAY_OF_MONTH_OR_EDIT_ALL_THE_TIME',
        JOURNALS_ARCHIVING: 'YES_OR_NO',
        ALL_JOURNALS_ACCESS: 'YES_OR_NO',
        JOURNALS_CHILDREN_DATES_EDIT: 'YES_OR_NO',
        GROUP_JOURNALS: {
            GROUP_JOURNALS_PRINT: 'YES_OR_NO',
            GROUP_JOURNALS_SCHEDULE: 'VIEW_AND_EDIT',
            GROUP_JOURNALS_TOPICS: 'VIEW_AND_EDIT',
            GROUP_JOURNALS_ATTENDANCE: 'VIEW_AND_EDIT',
            GROUP_JOURNALS_EVENTS: 'VIEW_AND_EDIT',
            GROUP_JOURNALS_CONSULTATIONS: 'VIEW_AND_EDIT',
            GROUP_JOURNALS_PSYCHOLOGICAL_HELP: 'VIEW_AND_EDIT',
            GROUP_JOURNALS_CHILDREN_LIST_VIEW: 'YES_OR_NO',
            GROUP_JOURNALS_INSPECTIONS: 'VIEW_AND_EDIT',
        },
        SPECIAL_JOURNALS: {
            SPECIAL_JOURNALS_PRINT: 'YES_OR_NO',
            SPECIAL_JOURNALS_MANAGE: 'YES_OR_NO',
            SPECIAL_JOURNALS_ATTENDANCE: 'VIEW_AND_EDIT',
            SPECIAL_JOURNALS_CONSULTATIONS: 'VIEW_AND_EDIT',
            SPECIAL_JOURNALS_INSPECTIONS: 'VIEW_AND_EDIT',
            SPECIAL_JOURNALS_QUALIFICATION_BASIS: 'VIEW_AND_EDIT',
            SPECIAL_JOURNALS_CHILDREN_LIST_VIEW: 'YES_OR_NO',
            SPECIAL_JOURNALS_TOPICS: 'VIEW_AND_EDIT',
            SPECIAL_JOURNALS_ADD_OUT_OF_TIMETABLE: 'YES_OR_NO',
            SPECIAL_JOURNALS_TIMETABLE_VIEW: 'YES_OR_NO',
        },
    },
    SETTLEMENT: {},
    DIET: {
        DIET_CONFIG: 'VIEW_AND_EDIT',
    },
    TIMETABLE: {
        TIMETABLE_OWN: 'VIEW_AND_EDIT',
        TIMETABLE_ALL_EMPLOYEES: 'YES_OR_NO',
    },
    MESSAGES: {
        ALL_MAIL_RECEIVERS_ACCESS: 'YES_OR_NO',
    },
    REMOTE_LESSONS: {
        REMOTE_LESSONS_OWN: 'VIEW_AND_EDIT',
        REMOTE_LESSONS_ALL: 'YES_OR_NO',
    },
    CALENDAR: {
        EVENTS: 'VIEW_AND_EDIT',
        ALL_EVENTS_ACCESS: 'YES_OR_NO',
    },
    EMPLOYEES: {
        EMPLOYEES_LIST: 'VIEW_AND_EDIT',
    },
    CHILDREN: {
        CHILDREN_LIST: 'VIEW_AND_EDIT',
        CHILDREN_ARCHIVING: 'YES_OR_NO',
    },
    GROUPS: {
        GROUPS_LIST: 'VIEW_AND_EDIT',
    },
    SETTINGS: {
        INSTITUTION_DATA: 'VIEW_AND_EDIT',
        PRINCIPAL_SETTINGS: 'VIEW_AND_EDIT',
        ROLES_AND_PERMISSIONS: 'VIEW_AND_EDIT',
        CHANGES_HISTORY: 'YES_OR_NO',
        PAYMENTS_SETTINGS: 'YES_OR_NO',
        SETTLEMENT_SETTINGS: 'VIEW_AND_EDIT',
        DIET_SETTINGS: 'VIEW_AND_EDIT',
    },
} as const;

const OTHER_PERMISSIONS: ActionForOtherPermission = {
    INSTITUTIONS_CREATE: 'YES_OR_NO',
};

export const ROLE_BASED_PERMISSIONS: Record<UserType, RoleBasedPermissionName[]> = {
    OWNER: ['MESSAGE_READ_STATUS'],
    EMPLOYEE: ['MESSAGE_READ_STATUS'],
    SUPPORT_OWNER: [],
    PARENT: [],
    SYS_ADMIN: [],
};

const ALL_PERMISSIONS = {
    ...EDITABLE_PERMISSIONS_BY_MODULE,
    ...OTHER_PERMISSIONS,
};

export const ACTION_TYPE_FOR_PERMISSIONS =
    flattenObject<Record<Permission['name'], PermissionType>>(ALL_PERMISSIONS);

export const ACTION_TYPE_FOR_EDITABLE_PERMISSIONS = omit(ACTION_TYPE_FOR_PERMISSIONS, [
    'INSTITUTIONS_CREATE',
]);
