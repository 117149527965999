export const formFields = {
    name: 'name',
    description: 'description',
    additionalInformation: 'additionalInformation',
    date: 'date',
    userId: 'userId',
    email: 'email',
    password: 'password',
    passwordRepeat: 'passwordRepeat',
    oldPassword: 'oldPassword',
    firstName: 'firstName',
    lastName: 'lastName',
    phoneNumber: 'phoneNumber',
    regonNumber: 'regonNumber',
    institutionName: 'institutionName',
    rspoNumber: 'rspoNumber',
    institutionAffiliation: 'institutionAffiliation',
    institutionType: 'institutionType',
    street: 'street',
    zipCode: 'zipCode',
    city: 'city',
    peselNumber: 'peselNumber',
    birthPlace: 'birthPlace',
    birthDate: 'birthDate',
    group: 'group',
    accountingPeriodStartDate: 'accountingPeriodStartDate',
    accountingPeriodFinishDate: 'accountingPeriodFinishDate',
    declaredStayHoursFromTime: 'declaredStayHoursFromTime',
    declaredStayHoursToTime: 'declaredStayHoursToTime',
    sex: 'sex',
    diet: 'diet',
    diseases: 'diseases',
    allergens: 'allergens',
    socialNetworksImageSharingAgreementInstagram: 'socialNetworksImageSharingAgreementInstagram',
    socialNetworksImageSharingAgreementFacebook: 'socialNetworksImageSharingAgreementFacebook',
    socialNetworksImageSharingAgreementTikTok: 'socialNetworksImageSharingAgreementTikTok',
    socialNetworksImageSharingAgreementWebsite: 'socialNetworksImageSharingAgreementWebsite',
    guardians: 'guardians',
    parents: 'parents',
    employeeRole: 'employeeRole',
    groups: 'groups',
    teacherIds: 'teacherIds',
    roleTemplateName: 'roleTemplateName',
    childrenIds: 'childrenIds',
    childIds: 'childIds',
    childIdsToAdd: 'childIdsToAdd',
    idNumber: 'idNumber',
    bankAccountNumber: 'bankAccountNumber',
    helpForm: 'helpForm',
    childId: 'childId',
    attachments: 'attachments',
    inspectionSubject: 'inspectionSubject',
    inspectionIdNumber: 'inspectionIdNumber',
    supervisionForm: 'supervisionForm',
    supervisor: 'supervisor',
    teacherId: 'teacherId',
    roleId: 'roleId',
    subject: 'subject',
    childrenWithParentsIds: 'childrenWithParentsIds',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    sunday: 'sunday',
    entryTime: 'entryTime',
    exitTime: 'exitTime',
    timeFrom: 'timeFrom',
    timeTo: 'timeTo',
    coreCurriculums: 'coreCurriculums',
    sendConsentToParents: 'sendConsentToParents',
    journalId: 'journalId',
    repetition: 'repetition',
    contactWith: 'contactWith',
    startDate: 'startDate',
    finishDate: 'finishDate',
    dateFrom: 'dateFrom',
    dateTo: 'dateTo',
    basis: 'basis',
    therapyProgram: 'therapyProgram',
    notes: 'notes',
    progressAnalysis: 'progressAnalysis',
    childrenWork: 'childrenWork',
    notInLessonChildrenWork: 'notInLessonChildrenWork',
    lessonId: 'lessonId',
    educationalGuide: 'educationalGuide',
    educationalGuideTopic: 'educationalGuideTopic',
    breakfastPrice: 'breakfastPrice',
    soupPrice: 'soupPrice',
    dinnerPrice: 'dinnerPrice',
    snackPrice: 'snackPrice',
    selectFromTeacherList: 'selectFromTeacherList',
    withNotInLessonChildren: 'withNotInLessonChildren',
    joinDate: 'joinDate',
    leaveDate: 'leaveDate',
    content: 'content',
    receivers: 'receivers',
    notInLessonChildren: 'notInLessonChildren',
    homeworkDescription: 'homeworkDescription',
    homeworkDeadline: 'homeworkDeadline',
    mealPaymentType: 'mealPaymentType',
    absenceReportType: 'absenceReportType',
    mealProviderType: 'mealProviderType',
    absenceReportTime: 'absenceReportTime',
    cateringTransferName: 'cateringTransferName',
    cateringAddress: 'cateringAddress',
    cateringAccountNumber: 'cateringAccountNumber',
    selectMealsType: 'selectMealsType',
    methodOfCalcTheFee: 'methodOfCalcTheFee',
    tuition: 'tuition',
    isFreeHours: 'isFreeHours',
    charge: 'charge',
    freeHours: 'freeHours',
    isTimeInterval: 'isTimeInterval',
    discountType: 'discountType',
    discountTarget: 'discountTarget',
    amount: 'amount',
    combinable: 'combinable',
    endDate: 'endDate',
    courseId: 'courseId',
    teacherFullName: 'teacherFullName',
    freeFromSchool: 'freeFromSchool',
    type: 'type',
    journalName: 'journalName',
    childLimit: 'childLimit',
    topic: 'topic',
    userIds: 'userIds',
    absenceType: 'absenceType',
    paid: 'paid',
    paymentDueTime: 'paymentDueTime',
    workingDays: 'workingDays',
    sameAddressAsInstitution: 'sameAddressAsInstitution',
    address: 'address',
    free: 'free',
    settlementConfig: 'settlementConfig',
    paymentConfig: 'paymentConfig',
    lessonsType: 'lessonsType',
    settlementType: 'settlementType',
    transactionType: 'transactionType',
    paymentFormType: 'paymentFormType',
    costAllocationType: 'costAllocationType',
    costAllocationTarget: 'costAllocationTarget',
    paymentType: 'paymentType',
    additionalLessonPaymentType: 'additionalLessonPaymentType',
    status: 'status',
    organizationName: 'organizationName',
    recipientName: 'recipientName',
    freeHoursStart: 'freeHoursStart',
    freeHoursEnd: 'freeHoursEnd',
    price: 'price',
    freeHoursTimeFrame: 'freeHoursTimeFrame',
    freeHoursAmount: 'freeHoursAmount',
    foundationName: 'foundationName',
    refersTo: 'refersTo',
    specificAmount: 'specificAmount',
    percentageAmount: 'percentageAmount',
    nip: 'nip',
    periodStart: 'periodStart',
    paymentDeadline: 'paymentDeadline',
    notInLessonDescription: 'notInLessonDescription',
    lessonName: 'lessonName',
    topicName: 'topicName',
} as const;
