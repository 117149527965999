import { JournalId, SpecialTopicId } from 'types';

export const SPECIAL_TOPICS_BY_WEEK = (journalId: JournalId) =>
    `/api/v1/journals/${journalId}/special-topics/weekly`;
export const SPECIAL_TOPICS = (journalId: JournalId) =>
    `/api/v1/journals/${journalId}/special-topics`;
export const SPECIAL_TOPIC = (journalId: JournalId, topicId: SpecialTopicId) =>
    `/api/v1/journals/${journalId}/special-topics/${topicId}`;
export const OUT_OF_TIMETABLE_SPECIAL_TOPICS = (journalId: JournalId) =>
    `/api/v1/journals/${journalId}/special-topics/out-of-timetable`;
