import { AppCardListItem, AppCardListItemProps } from 'components/atoms';
import { GroupTopicDTO } from 'types';
import { getFullName } from 'utils/getFullName';
import { getTimeDuration } from 'utils/getTimeDuration';
import { commonTexts, journalTexts } from 'consts/text';
import styled from 'styled-components';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';

type GroupJournalTopicCardProps = Omit<
    AppCardListItemProps<GroupTopicDTO>,
    'listItem' | 'children'
> & {
    topic: GroupTopicDTO;
};

const PreLineSpan = styled.span`
    white-space: pre-line;
`;

export const GroupJournalTopicCard = ({
    index,
    topic,
    options,
    isOptionsLoading,
}: GroupJournalTopicCardProps) => {
    const teachersContent = topic.teachers
        .map((teacher) => getFullName(teacher.firstName, teacher.lastName))
        .join(', ');

    return (
        <AppCardListItem
            index={index}
            listItem={topic}
            options={options}
            isOptionsLoading={isOptionsLoading}
        >
            <AppCardListItem.CardRow label={commonTexts.dataLabels.topic} content={topic.name} />
            <AppCardListItem.CardRow
                label={commonTexts.dataLabels.date}
                content={parseStringIntoFormattedStringDate(topic.date)}
            />
            <AppCardListItem.CardRow
                label={commonTexts.dataLabels.timeDuration}
                content={getTimeDuration(topic.timeFrom, topic.timeTo)}
            />
            <AppCardListItem.CardRow
                label={commonTexts.dataLabels.note}
                content={<PreLineSpan>{topic.description}</PreLineSpan>}
            />
            <AppCardListItem.CardRow
                label={journalTexts.journalDetails.groupJournal.topics.coreCurriculum}
                content={topic.coreCurriculums.join(', ')}
            />
            <AppCardListItem.CardRow
                label={commonTexts.dataLabels.teacher}
                content={teachersContent}
            />
        </AppCardListItem>
    );
};
