import { SpecialTopicChildWork, SpecialTopicDTO, SpecialTopicFormFields } from 'types';
import { parseStringIntoDayjsTime } from 'utils/parsers/dateTime/parseStringIntoDayjsTime';
import { getFullName } from 'utils/getFullName';
import { parseStringIntoDayjsDate } from '../dateTime/parseStringIntoDayjsDate';

export const parseSpecialTopicDTOIntoSpecialTopicFormFields: (
    topicDTO: Omit<SpecialTopicDTO, 'childrenWork' | 'notInLessonChildrenWork'> & {
        childrenWork: SpecialTopicChildWork[];
        notInLessonChildrenWork: SpecialTopicChildWork[];
    },
) => SpecialTopicFormFields = (topicDTO) => {
    const notInLessonChildIds = topicDTO.notInLessonChildrenWork.map(
        (notInLessonChildWork) => notInLessonChildWork.id,
    );

    return {
        name: topicDTO.name || '',
        description: topicDTO.description || '',
        teacherFullName: topicDTO.teacher
            ? getFullName(topicDTO.teacher.firstName, topicDTO.teacher.lastName)
            : '',
        timeFrom: parseStringIntoDayjsTime(topicDTO.timeFrom),
        timeTo: parseStringIntoDayjsTime(topicDTO.timeTo),
        date: parseStringIntoDayjsDate(topicDTO.date),
        childrenWork: topicDTO.childrenWork,
        notInLessonDescription: topicDTO.notInLessonDescription || '',
        notInLessonChildrenWork: topicDTO.notInLessonChildrenWork,
        notInLessonChildren: notInLessonChildIds,
        withNotInLessonChildren: !!notInLessonChildIds.length,
        lessonId: topicDTO.lesson.id,
    };
};
