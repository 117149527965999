import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { generatePath } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { SpecialTopicAddEditForm } from 'components/templates';
import { journalTexts } from 'consts/text';
import { SpecialTopicFormFields } from 'types/forms/specialTopic';
import { parseSpecialTopicFormFieldsIntoAddUpdateSpecialTopicDTO } from 'utils/parsers/specialTopic/parseSpecialTopicFormFieldsIntoAddUpdateSpecialTopicDTO';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useJournalAbilities } from 'abilities';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useJournalDetailsBreadcrumb } from '../../../hooks';
import { useSpecialJournalTopicAddPageNetworkManage } from './hooks';

export const SpecialJournalTopicAddPage = () => {
    const { form } = useEditForm<SpecialTopicFormFields>();
    const { journalId } = useJournalId();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { specialJournalsAbilities } = useJournalAbilities();

    const canAddOutOfTimetableTopics = specialJournalsAbilities.checkIfCanAddOutOfTimetableTopics();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const onAddTopicSuccess = () => {
        displaySuccess(journalTexts.journalDetails.groupJournal.topics.topicAddSuccessNotification);
        statefulNavigate(
            generatePath(appPaths.app.journals.specialJournalDetails.pickedJournal.topics.base, {
                journalId,
            }),
            {
                replace: true,
            },
        );
    };

    const {
        addSpecialTopic,
        addSpecialTopicError,
        isAddSpecialTopicLoading,
        specialLessonsData,
        handleSelectFormTopicDate,
        isSpecialLessonsDataFetching,
        isSpecialLessonsDataError,
    } = useSpecialJournalTopicAddPageNetworkManage({ onAddTopicSuccess, journalId });

    const onAddTopicFinish = (topicData: SpecialTopicFormFields) => {
        const parsedAddSpecialTopicDTO =
            parseSpecialTopicFormFieldsIntoAddUpdateSpecialTopicDTO(topicData);
        addSpecialTopic({
            journalId,
            addSpecialTopicDTO: parsedAddSpecialTopicDTO,
        });
    };

    if (canAddOutOfTimetableTopics) {
        const addOutOfTimetableTopicPathBase =
            appPaths.app.journals.specialJournalDetails.pickedJournal.topics[
                'add-out-of-timetable'
            ];
        statefulNavigate(generatePath(addOutOfTimetableTopicPathBase, { journalId }), {
            replace: true,
        });
    }

    return (
        <SpecialTopicAddEditForm
            mode="add"
            form={form}
            axiosError={addSpecialTopicError}
            isLoading={isAddSpecialTopicLoading}
            onFinish={onAddTopicFinish}
            specialLessonsData={specialLessonsData}
            handleSelectFormTopicDate={handleSelectFormTopicDate}
            isSpecialLessonsDataFetching={isSpecialLessonsDataFetching}
            isSpecialLessonsDataError={isSpecialLessonsDataError}
        />
    );
};
