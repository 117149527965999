/* eslint-disable @typescript-eslint/naming-convention */
export const WRONG_PASSWORD_FIREBASE_ERROR_CODE = 'auth/wrong-password';
export const unexpectedErrorLabel = 'Wystąpił niespodziewany błąd';
export const firebaseLoginErrors: Record<string, string> = {
    'auth/user-not-found': 'Wprowadzono niepoprawny adres e-mail lub hasło.',
    [WRONG_PASSWORD_FIREBASE_ERROR_CODE]: 'Wprowadzono niepoprawny adres e-mail lub hasło.',
    'auth/invalid-email': 'Wprowadzony adres e-mail jest niepoprawnie sformatowany.',
    'auth/user-disabled': 'Adres e-mail przypisany do konta jest nieaktywny.',
    'auth/invalid-action-code': 'Link do zmiany hasła wygasł.',
    'auth/too-many-requests': 'Zbyt wiele prób logowania. Spróbuj ponownie później.',
};
