import { useEffect, useMemo } from 'react';
import { EditablePermissionsNames, PermissionAction } from 'types';
import { useAtom } from 'jotai';
import { ACTION_TYPE_FOR_EDITABLE_PERMISSIONS } from 'consts/permissions/permissions';
import { permissionsEditAtom } from '../../roleAndPermissionState';
import { OnOffElement } from '../OnOffElement/OnOffElement';
import { addAdditionalPermissions, getElements, LabelsOption } from './PermissionOnOff.utils';

export type ModuleOnOffProps = {
    permissionName: EditablePermissionsNames;
    labelOption: LabelsOption;
    disabled?: boolean;
    defaultAction?: PermissionAction;
};

export const PermissionOnOff = ({
    permissionName,
    labelOption,
    disabled,
    defaultAction,
}: ModuleOnOffProps) => {
    const elements = useMemo(() => getElements(labelOption), [labelOption]);
    const [permissions, setPermissions] = useAtom(permissionsEditAtom);
    const permissionActionsType = ACTION_TYPE_FOR_EDITABLE_PERMISSIONS[permissionName];

    useEffect(() => {
        const isPermissionAdded = permissions.find(
            (permission) => permission.name === permissionName,
        );
        if (!(defaultAction && !isPermissionAdded)) {
            return;
        }
        setPermissions((prevPermissions) => [
            ...prevPermissions,
            { name: permissionName, actions: [defaultAction] },
        ]);
    }, [defaultAction, permissionName, permissions, setPermissions]);

    const isPermissionActive = useMemo<boolean>(() => {
        switch (permissionActionsType) {
            case 'YES_OR_NO':
                return !!permissions.find(({ name }) => name === permissionName);
            case 'EDIT_TO_10TH_DAY_OF_MONTH_OR_EDIT_ALL_THE_TIME':
                return !!permissions
                    .find(({ name }) => name === permissionName)
                    ?.actions?.includes('EDIT_TO_10TH_DAY_OF_MONTH');
            default:
                return false;
        }
    }, [permissionActionsType, permissionName, permissions]);

    const onChange = (value: boolean) => {
        setPermissions((prevPermissions) => {
            const nextPermissions = [...prevPermissions];
            const permissionIndex = nextPermissions.findIndex(
                (currentPermission) => currentPermission.name === permissionName,
            );
            if (permissionActionsType === 'YES_OR_NO') {
                if (value) {
                    nextPermissions.push({ name: permissionName, actions: [] });
                    addAdditionalPermissions(permissionName, nextPermissions);
                } else {
                    return nextPermissions.filter(({ name }) => name !== permissionName);
                }
            }

            if (permissionActionsType === 'EDIT_TO_10TH_DAY_OF_MONTH_OR_EDIT_ALL_THE_TIME') {
                const actions: PermissionAction[] = value
                    ? ['EDIT_TO_10TH_DAY_OF_MONTH']
                    : ['EDIT_ALL_THE_TIME'];

                if (permissionIndex === -1) {
                    nextPermissions.push({ name: permissionName, actions });
                }
                nextPermissions[permissionIndex] = {
                    name: permissionName,
                    actions,
                };
            }

            return nextPermissions;
        });
    };

    return (
        <OnOffElement
            disabled={disabled}
            active={isPermissionActive}
            elements={elements}
            onChange={onChange}
        />
    );
};
