import { Dayjs } from 'dayjs';
import { groupBy } from 'lodash';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { AbsenceDTO, LessonDTO, TimetableDTO } from 'types';
import { TimetableScheduleData } from 'components/templates/timetable/TimetableSchedule/TimetableSchedule.types';

export const getTimetableScheduleFromTimetable = (
    timetableData: TimetableDTO,
    selectedDate: Dayjs,
) => {
    const lessonData: LessonDTO[] = timetableData.lessons;
    const absencesData: AbsenceDTO[] = timetableData.absences;
    const groupedLessonsByDays = groupBy(lessonData, (lesson) =>
        parseStringIntoFormattedStringDate(lesson.date),
    );

    const groupedAbsencesByDays = groupBy(absencesData, (absence) =>
        parseStringIntoFormattedStringDate(absence.date),
    );

    const scheduleData: TimetableScheduleData = {
        monday: { date: '', lessons: [], absences: [] },
        tuesday: { date: '', lessons: [], absences: [] },
        wednesday: { date: '', lessons: [], absences: [] },
        thursday: { date: '', lessons: [], absences: [] },
        friday: { date: '', lessons: [], absences: [] },
        saturday: { date: '', lessons: [], absences: [] },
        sunday: { date: '', lessons: [], absences: [] },
    };

    const startOfWeekDate = selectedDate.startOf('week');

    Object.keys(scheduleData).forEach((day, index) => {
        const dayDate = startOfWeekDate.add(index, 'day');
        const parsedDayDate = parseDayjsDateIntoString(dayDate);
        scheduleData[day] = {
            date: parsedDayDate,
            lessons: groupedLessonsByDays[parsedDayDate] || [],
            absences: groupedAbsencesByDays[parsedDayDate] || [],
        };
    });
    return scheduleData;
};
