import { settingsTexts } from 'consts/text';
import { EditablePermissionsNames, Permission } from 'types';

export type LabelsOption = 'default' | 'month10day';

const LABELS: Record<LabelsOption, Record<'on' | 'off', string>> = {
    default: {
        on: settingsTexts.rolesAndPermissions.permissionsOnOff.on,
        off: settingsTexts.rolesAndPermissions.permissionsOnOff.off,
    },
    month10day: {
        on: settingsTexts.rolesAndPermissions.permissionSwitchLabels.EDIT_TO_10TH_DAY_OF_MONTH,
        off: settingsTexts.rolesAndPermissions.permissionSwitchLabels.EDIT_ALL_THE_TIME,
    },
};

export const getElements: (labelOptions: LabelsOption) => { label: string; value: boolean }[] = (
    labelOptions,
) => [
    {
        label: LABELS[labelOptions].off,
        value: false,
    },
    {
        label: LABELS[labelOptions].on,
        value: true,
    },
];

export const addAdditionalPermissions: (
    currentPermissionName: EditablePermissionsNames,
    permissionsResult: Permission[],
) => Permission[] = (currentPermissionName, permissionsResult) => {
    if (currentPermissionName === 'SPECIAL_JOURNALS_ADD_OUT_OF_TIMETABLE') {
        const specialJournalsTopicsPermission = permissionsResult.find(
            ({ name }) => name === 'SPECIAL_JOURNALS_TOPICS',
        );
        if (!specialJournalsTopicsPermission) {
            permissionsResult.push({
                name: 'SPECIAL_JOURNALS_TOPICS',
                actions: ['VIEW', 'EDIT_AND_ADD'],
            });
            return permissionsResult;
        }
        const isSpecialJournalsTopicsPermissionAddAndEditActionIncluded =
            specialJournalsTopicsPermission.actions?.includes('EDIT_AND_ADD');
        if (!isSpecialJournalsTopicsPermissionAddAndEditActionIncluded) {
            return permissionsResult.map((permission) => {
                if (permission.name !== 'SPECIAL_JOURNALS_TOPICS') {
                    return permission;
                }
                return {
                    ...permission,
                    actions: ['VIEW', 'EDIT_AND_ADD'],
                };
            });
        }
    }
    return permissionsResult;
};
