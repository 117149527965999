import {
    AddOutOfTimetableSpecialTopicDTO,
    EmployeeId,
    OutOfTimetableSpecialTopicFormFields,
} from 'types';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { parseDayjsTimeIntoHoursMinutesFormat } from 'utils/parsers/dateTime/parseDayjsTimeIntoHoursMinutesFormat';
import { getAddUpdateChildWorkDTOsFromNotInLessonChildrenWork } from 'utils/getAddUpdateChildWorkDTOsFromNotInLessonChildrenWork';

export const parseOutOfTimetableSpecialTopicFormFieldsIntoAddOutOfTimetableSpecialTopicDTO: (
    outOfTimetableSpecialTopicFormFields: OutOfTimetableSpecialTopicFormFields,
    employeeId: EmployeeId,
) => AddOutOfTimetableSpecialTopicDTO = (outOfTimetableSpecialTopicFormFields, employeeId) => {
    const { lessonName, topicName, date, timeFrom, timeTo, description, childrenWork, childIds } =
        outOfTimetableSpecialTopicFormFields;

    const mappedChildrenWork = getAddUpdateChildWorkDTOsFromNotInLessonChildrenWork(
        childIds,
        childrenWork,
    );
    return {
        lessonName,
        topicName,
        date: parseDayjsDateIntoString(date, 'YYYY-MM-DD'),
        timeFrom: parseDayjsTimeIntoHoursMinutesFormat(timeFrom),
        timeTo: parseDayjsTimeIntoHoursMinutesFormat(timeTo),
        description,
        teacherId: employeeId,
        childrenWork: mappedChildrenWork,
    };
};
