import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { useWatch } from 'antd/es/form/Form';
import { ChildId, OutOfTimetableSpecialTopicFormFields, SpecialTopicChildWork } from 'types';
import { commonTexts, journalTexts, timetableTexts } from 'consts/text';
import { AppForm, AppFormProps, JournalChildPicker } from 'components/organisms';
import {
    DatePickerElement,
    FormNavButtons,
    TopicHoursTimePicker,
    TopicNameInputElement,
    SimpleTextAreaElement,
    SimpleInputElement,
} from 'components/molecules';
import { AppFormItem } from 'components/atoms';
import { ChildrenWork } from 'components/organisms/FormItems/ChildrenWork/ChildrenWork';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { specialTopicAddEditErrorParser } from 'utils/errorHandlers/specialTopic/specialTopicAddEditErrorParser';
import { formFields } from 'consts/form/formFields';
import { max300LengthRule, max5000LengthRule, min2LengthRule } from 'consts/rules/textRules';
import { parseNotInLessonChildIntoSpecialTopicChildWork } from 'utils/parsers/specialTopic/parseNotInLessonChildIntoSpecialTopicChildWork';
import { useDependentFormFields } from 'hooks/useDependentFormFields/useDependentFormFields';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';

type OutOfTimetableSpecialTopicAddEditFormProps = Omit<
    AppFormProps<OutOfTimetableSpecialTopicFormFields>,
    'mode' | 'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Required<Pick<AppFormProps<OutOfTimetableSpecialTopicFormFields>, 'form'>> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (outOfTimetableSpecialTopicData: OutOfTimetableSpecialTopicFormFields) => void;
    };

export const OutOfTimetableSpecialTopicAddEditForm = ({
    initialValues,
    axiosError,
    form,
    ...props
}: OutOfTimetableSpecialTopicAddEditFormProps) => {
    const { journalId } = useJournalId();

    const {
        watchedFieldsValues: watchedDateValue,
        handleWatchedFieldsValuesChange: handleWatchedDateValueChange,
    } = useDependentFormFields({
        watchedFields: [formFields.date],
        dependentFields: [formFields.childIds],
        formInstance: form,
    });

    const childIdsFormValues = useWatch<ChildId[] | undefined>(formFields.childIds, form);

    const { date: dateValue } = watchedDateValue;
    const parsedDate = dateValue ? parseDayjsDateIntoString(dateValue, 'YYYY-MM-DD') : undefined;

    const outOfTimetableSpecialTopicAddEditError = axiosError
        ? specialTopicAddEditErrorParser(axiosError)
        : '';

    const { data: childrenData } = useAppQuery(
        'JOURNAL_CHILDREN',
        [journalId, parsedDate, parsedDate],
        () =>
            StaffHTTPService.journals.children.getJournalChildren(journalId, {
                dateFrom: parsedDate,
                dateTo: parsedDate,
            }),
        {
            enabled: false,
        },
    );

    const childrenWork = useMemo(() => {
        if (!childrenData || !childIdsFormValues) {
            return undefined;
        }
        const selectedChildren = childrenData.filter((child) =>
            childIdsFormValues.includes(child.id),
        );
        const parsedChildrenWork: SpecialTopicChildWork[] = selectedChildren.map(
            parseNotInLessonChildIntoSpecialTopicChildWork,
        );
        return parsedChildrenWork;
    }, [childIdsFormValues, childrenData]);

    return (
        <AppForm<OutOfTimetableSpecialTopicFormFields>
            maxWidth={570}
            name="outOfTimetableSpecialTopicAdd"
            title={journalTexts.journalDetails.groupJournal.topics.topicAddFormTitle}
            error={outOfTimetableSpecialTopicAddEditError}
            withGoBack
            form={form}
            {...props}
        >
            <TopicNameInputElement
                name={formFields.topicName}
                rules={[min2LengthRule, max300LengthRule]}
                optional
                hasOptionalText
            />
            <DatePickerElement isHalfParentWidth onChange={handleWatchedDateValueChange} />
            <SimpleInputElement
                name={formFields.lessonName}
                label={commonTexts.dataLabels.lessonName}
            />
            <AppFormItem justifyContent="space-between" doubleItem>
                <TopicHoursTimePicker mode="start" />
                <TopicHoursTimePicker mode="finish" />
            </AppFormItem>
            <JournalChildPicker
                label={commonTexts.actionLabels.selectChildren}
                journalId={journalId}
                dateFrom={parsedDate}
                dateTo={parsedDate}
                multiple
                disabled={!parsedDate}
            />
            <SimpleTextAreaElement
                name={formFields.description}
                label={timetableTexts.lessons.lessonsDescription}
                optional
                hasOptionalText
                rules={[max5000LengthRule]}
                rows={6}
            />
            <ChildrenWork childrenWork={childrenWork} />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={commonTexts.actionLabels.saveChanges}
                withPlus
            />
        </AppForm>
    );
};
