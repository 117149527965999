import { ChildId, SpecialTopicChildWork } from 'types';

export const getAddUpdateChildWorkDTOsFromNotInLessonChildrenWork = (
    childIds: ChildId[],
    childrenWork?: SpecialTopicChildWork[],
) =>
    childIds.map((childId) => {
        const childWorkData = childrenWork?.find((childWork) => childWork.id === childId);
        return {
            childId,
            description: childWorkData?.description,
        };
    });
