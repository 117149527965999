import { ChildWorkDTO } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const parseChildWorkDTOIntoJsonObject: (childWorkDTO: ChildWorkDTO) => object = (
    childWorkDTO,
) => {
    const { child, description } = childWorkDTO;
    const { firstName, lastName } = child;

    const childJsonObject = {
        [jsonObjectsTexts.specialTopicDTO.innerObjects.childWorkDTO.innerObjects.lessonChildDTO
            .fields.firstname]: firstName,
        [jsonObjectsTexts.specialTopicDTO.innerObjects.childWorkDTO.innerObjects.lessonChildDTO
            .fields.lastname]: lastName,
    };

    return {
        [jsonObjectsTexts.specialTopicDTO.innerObjects.childWorkDTO.fields.child]: childJsonObject,
        [jsonObjectsTexts.specialTopicDTO.innerObjects.childWorkDTO.fields.description]:
            description || EMPTY_VALUE_LABEL,
    };
};
