import { AddUpdateSpecialTopicDTO, SpecialTopicFormFields } from 'types';
import { getAddUpdateChildWorkDTOsFromNotInLessonChildrenWork } from 'utils/getAddUpdateChildWorkDTOsFromNotInLessonChildrenWork';

export const parseSpecialTopicFormFieldsIntoAddUpdateSpecialTopicDTO: (
    specialTopicFormFields: SpecialTopicFormFields,
) => AddUpdateSpecialTopicDTO = (specialTopicFormFields) => {
    const mappedChildrenWork = specialTopicFormFields.childrenWork?.map((childWork) => ({
        childId: childWork.id,
        description: childWork.description,
    }));
    const mappedNotInLessonChildrenWork = specialTopicFormFields.notInLessonChildren
        ? getAddUpdateChildWorkDTOsFromNotInLessonChildrenWork(
              specialTopicFormFields.notInLessonChildren,
              specialTopicFormFields.notInLessonChildrenWork,
          )
        : undefined;

    return {
        name: specialTopicFormFields.name,
        lessonId: specialTopicFormFields.lessonId,
        description: specialTopicFormFields.description,
        notInLessonDescription: specialTopicFormFields.notInLessonDescription,
        childrenWork: mappedChildrenWork,
        notInLessonChildrenWork: mappedNotInLessonChildrenWork,
    };
};
