import { generatePath } from 'react-router-dom';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { OutOfTimetableSpecialTopicFormFields } from 'types';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { journalTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { OutOfTimetableSpecialTopicAddEditForm } from 'components/templates';
import { parseOutOfTimetableSpecialTopicFormFieldsIntoAddOutOfTimetableSpecialTopicDTO } from 'utils/parsers/specialTopic/parseOutOfTimetableSpecialTopicFormFieldsIntoAddOutOfTimetableSpecialTopicDTO';
import { useUserDetails } from 'jotaiAtoms/userDetails';
import { ErrorDisplay } from 'components/molecules';
import { useSpecialJournalOutOfTimetableTopicAddPageNetworkManage } from './hooks';

export const SpecialJournalOutOfTimetableTopicAddPage = () => {
    const { form } = useEditForm<OutOfTimetableSpecialTopicFormFields>();
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { journalId } = useJournalId();
    const userDetails = useUserDetails();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const onAddOutOfTimetableTopicSuccess = () => {
        displaySuccess(journalTexts.journalDetails.groupJournal.topics.topicAddSuccessNotification);
        statefulNavigate(
            generatePath(appPaths.app.journals.specialJournalDetails.pickedJournal.topics.base, {
                journalId,
            }),
            {
                replace: true,
            },
        );
    };

    const {
        addOutOfTimetableSpecialTopic,
        employeeData,
        addOutOfTimetableSpecialTopicError,
        isEmployeeDataError,
        isAddOutOfTimetableSpecialTopicLoading,
        isEmployeeDataLoading,
    } = useSpecialJournalOutOfTimetableTopicAddPageNetworkManage({
        onAddOutOfTimetableTopicSuccess,
        journalId,
        userId: userDetails.id,
    });

    const onAddOutOfTimetableTopicFinish = (
        outOfTimetableTopicData: OutOfTimetableSpecialTopicFormFields,
    ) => {
        const userEmployeeId = employeeData?.id;
        if (!userEmployeeId) {
            return;
        }
        const parsedAddOutOfTimetableSpecialTopicDTO =
            parseOutOfTimetableSpecialTopicFormFieldsIntoAddOutOfTimetableSpecialTopicDTO(
                outOfTimetableTopicData,
                userEmployeeId,
            );
        addOutOfTimetableSpecialTopic({
            journalId,
            addOutOfTimetableSpecialTopicDTO: parsedAddOutOfTimetableSpecialTopicDTO,
        });
    };

    if (isEmployeeDataError) {
        return <ErrorDisplay />;
    }

    return (
        <OutOfTimetableSpecialTopicAddEditForm
            form={form}
            axiosError={addOutOfTimetableSpecialTopicError}
            isLoading={isAddOutOfTimetableSpecialTopicLoading || isEmployeeDataLoading}
            onFinish={onAddOutOfTimetableTopicFinish}
        />
    );
};
