import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { JournalId, UserId } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type SpecialJournalOutOfTimetableTopicAddPageNetworkManageProps = {
    onAddOutOfTimetableTopicSuccess: () => void;
    journalId: JournalId;
    userId: UserId;
};

export const useSpecialJournalOutOfTimetableTopicAddPageNetworkManage = ({
    onAddOutOfTimetableTopicSuccess,
    journalId,
    userId,
}: SpecialJournalOutOfTimetableTopicAddPageNetworkManageProps) => {
    const {
        mutate: addOutOfTimetableSpecialTopic,
        error: addOutOfTimetableSpecialTopicError,
        isLoading: isAddOutOfTimetableSpecialTopicLoading,
    } = useAppMutation(StaffHTTPService.specialTopics.addOutOfTimetableSpecialTopic, {
        key: ['ADD_OUT_OF_TIMETABLE_SPECIAL_TOPIC'],
        onSuccess: onAddOutOfTimetableTopicSuccess,
        invalidateQueryKeys: [
            ['SPECIAL_TOPICS', journalId],
            ['SPECIAL_ATTENDANCE', journalId],
            ['SPECIAL_DAILY_ATTENDANCE', journalId],
            ['SPECIAL_MONTHLY_ATTENDANCE', journalId],
            ['EMPLOYEE_SCHEDULE'],
            ['UNASSIGNED_CHILDREN'],
        ],
    });

    const {
        data: employeeData,
        isLoading: isEmployeeDataLoading,
        isError: isEmployeeDataError,
    } = useAppQuery(
        'EMPLOYEE_BY_USER_ID',
        [userId],
        () => StaffHTTPService.employees.getEmployeeByUserId(userId),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    return {
        addOutOfTimetableSpecialTopic,
        employeeData,
        addOutOfTimetableSpecialTopicError,
        isEmployeeDataLoading,
        isAddOutOfTimetableSpecialTopicLoading,
        isEmployeeDataError,
    };
};
