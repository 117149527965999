import { ChildSimpleDTO, SpecialTopicChildWork } from 'types';

export const parseNotInLessonChildIntoSpecialTopicChildWork: (
    notInLessonChild: Pick<ChildSimpleDTO, 'id' | 'firstName' | 'lastName'>,
) => SpecialTopicChildWork = (notInLessonChild) => {
    const { id, firstName, lastName } = notInLessonChild;

    return {
        id,
        firstName,
        lastName,
        description: null,
        absentType: 'PRESENT',
    };
};
